import React, { useEffect, useState } from 'react';
import { range } from "lodash";
import { addDoc, collection, doc, deleteDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {YEAR} from "../App";


const r = range(10)
const Picks = ({ loserScores, winnerScores, setUser, logout, createUser, user, picks, updateUser }) => {
    console.log('user:', !user?.id);
    const getPick = (row, column) => {
        const pick = picks.find(pick => {
            return pick.column === column && pick.row === row
        })
        if (!pick) {
            if (user?.id) {
                return (
                    <i onClick={() => makePick(row, column)} className="material-icons opacity-10 text-success checkmark" style={{width: '100%'}}>check</i>
                )
            } else {
                return '';
            }
        }

        return (<div>
            <div className="pick-cell">{pick.user.name}</div>
            {showRemoveIcon(pick)}</div>);
    }
    const makePick = async (row, column) => {
        console.log('row, column:', row, column);
        await addDoc(collection(db, 'picks'), {
            column,
            row,
            user: user,
            year: YEAR,
        });
    }
    const removePick = async pick => {
        await deleteDoc(doc(db, "picks", pick.id));
    }
    const showRemoveIcon = pick => {
        if (!user.id) return '';
        if (user.id === pick.user.id) {
            return <i onClick={() => removePick(pick)} className="text-danger checkmark">x</i>
        } else {
            return '';
        }
    }
    useEffect(() => {
        if (picks) {
            const userPicks = picks.filter(p => {
                return p.user.id === user.id
            })
            const totalPicks = userPicks.length
            updateUser(user, totalPicks)
        }

    }, [picks])
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [createPlayer, setCreatePlayer] = useState(false)
    const updateEmail = e => {
        setEmail(e.currentTarget.value)
    }
    const updateName = e => {
        setName(e.currentTarget.value)
    }
    const checkEnter = e => {
        if (e.key === 'Enter') {
            getPlayer(email)
        }
    }
    const handleClose = async () => {
        setCreatePlayer(false)
        await createUser({
            email,
            name,
            count: 0,
            paid: 0,
        })
        getPlayer(email)
    }
    const getPlayer = async (email) => {
        if (email == '') {
            alert("Enter your email address to log in")
            return
        }
        const q = query(collection(db, "players"), where("email", "==", email.toLowerCase()));
        const querySnapshot = await getDocs(q);
        const isEmpty = querySnapshot.empty
        if (isEmpty) setCreatePlayer(true)
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            setUser({ ...doc.data(), id: doc.id });
        });

    }

    return (
        <div>
            <div className="d-flex flex-column">
                {!user?.id ? (
                    <>
                        <div >Enter your email to make your picks:</div>
                        <div className="row">
                        <div className="form-floating mb-3 col-md-4">
                            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={email} onChange={updateEmail} onKeyUp={checkEnter} style={{paddingLeft: 15}} required/>
                            <label htmlFor="floatingInput" style={{left: 20}}>Email address</label>
                        </div>
                        <div className="form-floating mb-3 col-4">
                            <a onClick={() => getPlayer(email)} className="nav-link text-body font-weight-bold">
                                <i className="fa fa-user me-sm-1" aria-hidden="true"></i>
                                <span className="d-sm-inline">Sign In</span>
                            </a>
                        </div>
                        </div>
                        <Modal show={createPlayer} onHide={handleClose} contentClassName="dark-version">
                            <Modal.Header closeButton>
                                <Modal.Title>Add Player</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" onChange={updateEmail} value={email}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" onChange={updateName} />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : (
                    <>
                    <div  className="nav-link text-body font-weight-bold px-0">{user.name} - {user.email}</div>
                    <a onClick={() => logout(email)} className="nav-link text-body font-weight-bold px-0">
                        <i className="fa fa-user me-sm-1" aria-hidden="true"></i>
                        <span> Sign Out</span>
                    </a>
                    </>
                )}
            </div>

            <table className="table align-items-center justify-content-center mb-0" id="picks-table">
                <tbody>
                <tr>
                    <th colSpan={12}>Winner's Score</th>
                </tr>
                <tr>
                    <th rowSpan={11} style={{
                        width: 50,
                    }}>L<br/>o<br/>s<br/>e<br/>r<br/>'<br/>s<br/><br/> S<br/>c<br/>o<br/>r<br/>e
                    </th>
                    <td>&nbsp;</td>
                    {winnerScores.map((num, i) => (<td key={i}>{num}</td>))}
                </tr>
                {r.map(row => (
                    <tr key={row}>
                        <th>{loserScores[row]}</th>
                        {r.map(column => (
                            <td key={`${column}${row}`}>{getPick(row, column)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default Picks;
